import { FC, useEffect, useState } from 'react'
import { useErrorHandler } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'
import { MdError } from 'react-icons/md'
import { useHistory } from 'react-router-dom'
import {
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  HStack,
  Icon,
  Input,
  Spinner,
  Text,
  useMultiStyleConfig,
  VStack,
} from '@chakra-ui/react'
import axios from 'axios'
import { useAuth } from 'context/AuthContext'
import { useCampaignContext } from 'context/CampaignContext'
import _ from 'lodash'

import {
  OTP_EXPIRY_IN_SECONDS,
  RATE_LIMIT_INTERVAL_IN_SECONDS,
  RESEND_OTP_INTERVAL_IN_SECONDS,
} from 'constants/config'
import { FormPhase } from 'constants/types'
import { useVerifyOtp } from 'hooks/Otp'
import { useSubmitVoucherClaim } from 'hooks/Submit'
import useControlledInput from 'hooks/useControlledInput'
import useTimer from 'hooks/useTimer'
import { formatAreaCodeContact } from 'utils/helpers'
import { DDAction } from 'utils/monitoring'
import { isClientError, isTooManyRequestsError } from 'utils/service'
import { registerOtp } from 'services/RedeemApi'
import BlinkingCursor from 'components/BlinkingCursor'
import { Heading } from 'components/Heading'

import { OtpFormHelp } from 'pages/OtpForm/components/OtpFormHelp'

export const OtpFormPage: FC = () => {
  const { t, i18n } = useTranslation('verify')

  const history = useHistory()
  const handleError = useErrorHandler()
  const [otpTextField, setOtpTextField] = useState('')

  const { person, phone, setVoucherUrl, setFormPhase } = useAuth()
  const { campaign, routeCampaignId } = useCampaignContext()

  const { mutateAsync: verifyOtpAsync, isLoading: isLoadingVerifyOtp } =
    useVerifyOtp()
  const { mutateAsync: submitClaimAsync, isLoading: isLoadingSubmitClaim } =
    useSubmitVoucherClaim()
  const [isOtpResent, setIsOtpResent] = useState(false)

  const onResendOtpTimerEndCallback = () => {
    setIsOtpResent(false)
  }

  // Timer related to resend otp
  const { timer: resendOtpTimer, startCountdown: startResendOtpCountdown } =
    useTimer({
      duration: RESEND_OTP_INTERVAL_IN_SECONDS,
      autoStart: true,
      callbackFnOnTimerEnd: onResendOtpTimerEndCallback,
    })

  const canResendOtp = resendOtpTimer === 0

  // Previously we were using the controlledinput to remove the value. Does it make sense? Would prefer to keep the value and let user clear it.
  const { inputError, resetField, setValidValue, setInputError } =
    useControlledInput({ initialValue: '' })

  const rateLimitTimerErrorCallback = (timer: number) => {
    setInputError({
      message: `Please wait another ${timer} seconds before trying to request for another OTP again.`,
      keepValue: false,
    })
  }
  const onRateLimitTimerEndCallback = () => {
    setInputError({
      message: '',
    })
  }
  // Timer related to preventing users from
  // verifying upon receiving 429 error from server
  const {
    timer: otpReverifyTimer,
    startCountdown: startOtpReverifyCountdown,
    isTimerRunning: isOtpReverifyCountdownRunning,
  } = useTimer({
    duration: RATE_LIMIT_INTERVAL_IN_SECONDS,
    callbackFn: rateLimitTimerErrorCallback,
    callbackFnOnTimerEnd: onRateLimitTimerEndCallback,
  })

  const isOtpVerificationDisabled =
    otpReverifyTimer >= 0 && isOtpReverifyCountdownRunning

  const styles = useMultiStyleConfig('FormInput', { variant: 'otp' })

  const handleOnChange = (value: string) => {
    if (value.length > 4) {
      return
    } else if (value.length === 4) {
      onSubmit(value)
    }
    if (!isOtpVerificationDisabled) {
      setValidValue(value)
    }
  }

  const onSubmit = async (otp: string) => {
    if (isOtpVerificationDisabled) {
      return
    }

    // async utilised in this case due to multiple synchronous requests
    // TODO: swap over to a single endpoint for this chained call
    try {
      const phoneJwt = await verifyOtpAsync({
        contactNumber: phone,
        otp,
        campaignId: routeCampaignId,
      })
      const data = await submitClaimAsync(
        {
          contactNumberJwt: phoneJwt,
          lang: i18n.language,
          campaignId: routeCampaignId,
          personJwt: person?.personJwt as string,
        },
        {},
      )
      setVoucherUrl(data.voucherUrl)
      setFormPhase(FormPhase.SUCCESS)
      history.push(`/${campaign?.id}/voucher?existing=${data.isExisting}`)
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (err.response?.status === 401) {
          return setInputError({
            message: t('invalidOtp'),
          })
        }
        if (isClientError(err)) {
          if (isTooManyRequestsError(err)) {
            return startOtpReverifyCountdown()
          }
          return setInputError({
            message: err.response?.data.error.message,
            keepValue: false,
          })
        }
      }
      handleError(err)
    }
  }

  return (
    <>
      <Heading
        title={t('enterOtp', { contactNumber: formatAreaCodeContact(phone) })}
        content={t('enterOtpInfo', { otpExpiry: OTP_EXPIRY_IN_SECONDS })}
      />
      <FormControl
        isInvalid={!!inputError}
        position="relative"
        height="fit-content"
      >
        <HStack
          alignItems="center"
          justifyContent="center"
          border="2px solid"
          borderColor={inputError ? 'danger.500' : 'primary.500'}
          borderRadius="5px"
          height="54px"
          position="absolute"
          top="0"
          left="0"
          width="100%"
          zIndex={2}
          pointerEvents="none"
        >
          {_.range(4).map((index) => (
            <Flex
              _notLast={{
                borderRight: '1px solid',
                borderColor: inputError ? 'danger.500' : 'neutral.400',
              }}
              height="100%"
              flex={1}
              alignItems="center"
              justifyContent="center"
              key={index}
            >
              <Text textStyle="h3" color="neutral.900">
                {otpTextField[index] && otpTextField[index]}
              </Text>
              {otpTextField.length === index && <BlinkingCursor />}
            </Flex>
          ))}
        </HStack>

        <VStack width="100%" zIndex={1} alignItems="flex-start">
          <Input
            onChange={(e) => {
              const otpKeyed = e.target.value
              setOtpTextField(otpKeyed)
              handleOnChange(otpKeyed)
            }}
            autoFocus={true}
            type="number"
            pattern="\d*"
            name="otp"
            autoComplete="one-time-code"
            border="2px solid"
            focusBorderColor={inputError ? 'danger.500' : 'primary.500'}
            borderRadius="5px"
            height="54px"
            color="transparent" // Hides the text
            style={{
              // Used to hide the caret for ios device
              caretColor: 'transparent',
              WebkitTextFillColor: 'transparent',
            }}
            _autofill={{
              color: 'transparent',
            }}
          />
          {resendOtpTimer >= RESEND_OTP_INTERVAL_IN_SECONDS - 5 &&
            isOtpResent && (
              <FormHelperText sx={styles.helper}>{t('newOtp')}</FormHelperText>
            )}

          <FormErrorMessage>
            <HStack sx={styles.error}>
              <Icon as={MdError} />
              <Text id="otp-input-error">{inputError}</Text>
            </HStack>
          </FormErrorMessage>
        </VStack>
      </FormControl>

      <Button
        variant="link"
        id="resend-otp"
        textDecoration="underline"
        alignSelf="center"
        onClick={async () => {
          await registerOtp({
            contactNumber: phone,
            campaignId: routeCampaignId,
            lang: i18n.language,
          })
          startResendOtpCountdown()
          setIsOtpResent(true)
          resetField()
        }}
        disabled={!canResendOtp}
        data-dd-action-name={DDAction.RESEND_OTP_BUTTON}
      >
        {/* Shortcut to pad a 0 if length digit < 2 */}
        {t('resendOtp')}{' '}
        {!canResendOtp && `00:${('0' + resendOtpTimer).slice(-2)}`}
      </Button>

      {isLoadingVerifyOtp || isLoadingSubmitClaim ? (
        <Center>
          <Spinner thickness="3px" />
        </Center>
      ) : (
        campaign && <OtpFormHelp t={t} campaign={campaign} />
      )}
    </>
  )
}
